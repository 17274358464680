<template>
    <div
        class="poll-activity-chart"
        :style="{ '--height': height }">
        <pendo-status-overlay :state="aggState">
            <div
                ref="poll-activity"
                class="pendo-highcharts-container" />
        </pendo-status-overlay>
    </div>
</template>

<script>
import { PendoStatusOverlay } from '@pendo/components';
import { LOADING_STATES } from '@pendo/services/Constants';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

export default {
    name: 'PollActivityChart',
    components: {
        PendoStatusOverlay
    },
    props: {
        poll: {
            type: Object,
            required: true
        },
        aggState: {
            type: String,
            default: LOADING_STATES.LOADING
        },
        height: {
            type: String,
            default: 'auto'
        },
        responseCounts: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            chart: null,
            chartConfig: {}
        };
    },
    computed: {
        pollType () {
            return get(this.poll, 'attributes.type');
        },
        series () {
            const lookup = keyBy(this.responseCounts, 'pollResponse');

            return [
                {
                    name: 'Responses',
                    data: this.categories.map((category) => {
                        return get(lookup, `['${category}'].count`, 0);
                    })
                }
            ];
        },
        isNumericRating () {
            return ['NumberScale', 'NPSRating'].includes(this.pollType);
        },
        categories () {
            if (this.isNumericRating) {
                return this.poll.numericResponses;
            }

            return Object.keys(this.poll.idResponses);
        }
    },
    watch: {
        responseCounts () {
            this.chartConfig = this.getChartConfig();
            if (this.$refs['poll-activity']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['poll-activity'], this.chartConfig);
            }
        }
    },
    methods: {
        getChartConfig () {
            const vm = this;

            return {
                series: this.series,
                chart: {
                    type: 'bar',
                    height: 65 * this.categories.length,
                    spacing: [0, 0, 0, 0]
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                xAxis: {
                    categories: this.categories,
                    crosshair: false,
                    labels: {
                        formatter () {
                            if (get(vm, 'isNumericRating')) {
                                return this.value;
                            }

                            return vm.poll.idResponses[this.value];
                        },
                        y: null,
                        align: 'center'
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                    min: 0,
                    allowDecimals: false,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                title: { text: null },
                tooltip: {
                    enabled: false
                },
                colors: ['#229CA8']
            };
        }
    }
};
</script>

<style lang="scss">
.poll-activity-chart {
    padding: 16px;
    min-height: var(--height);

    .pendo-highcharts-container {
        min-height: 130px;
    }
}
</style>
