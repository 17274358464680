var render = function render(){var _vm=this,_c=_vm._self._c;return _c('segment-builder',{attrs:{"config":{
        appId: _vm.activeApp.id,
        userId: _vm.user.id,
        data: {
            appFirstVisit: _vm.firstVisit,
            apps: _vm.listAllForActiveSubscription,
            schemas: _vm.filteredSchemaList,
            guides: { map: _vm.guideMap, filteredList: () => _vm.guideList },
            rcModules: { map: _vm.rcModulesMap, filteredList: () => _vm.rcModulesList },
            draftResourceCenters: { map: _vm.draftResourceCenterMap, filteredList: () => _vm.draftResourceCenterList },
            pages: { map: _vm.pageMap, filteredList: () => _vm.pageList },
            features: { map: _vm.featureMap, filteredList: () => _vm.featureList },
            workflows: { map: _vm.workflowMap, filteredList: () => _vm.workflowsList },
            segments: { map: _vm.segmentsMap, filteredList: () => _vm.segmentsListByGroup.customList },
            trackTypes: { map: _vm.trackTypeMap, filteredList: () => _vm.trackTypeList }
        },
        filters: { timeSeries: _vm.activeTimeSeries },
        pendoHttpWrapperConfig: _vm.http.defaults,
        usesMultiApp: _vm.usesMultiApp,
        hasAdoptCustomPageSegments: _vm.hasAdoptCustomPageSegments,
        guideTargeting: _vm.editingGuideSegment,
        hasResourceCenter: _vm.activeHasResourceCenter,
        hasWorkflow: _vm.activeHasAnalytics,
        workflowWithinLastDateRange: _vm.workflowWithinLastDateRange,
        showWorkflowRicherSegmentation: true,
        showAppicons: true,
        canShowAnonymous: _vm.canShowAnonymousVisitors,
        useTrackTypes: _vm.adoptTrackEventFlag
    },"editing-segment":_vm.editingSegment,"is-saving":_vm.isSavingSegment,"is-visible":_vm.visible,"show-super-panel":_vm.isSuper},on:{"save":_vm.onSegmentSave,"close":_vm.closeSegmentBuilder}})
}
var staticRenderFns = []

export { render, staticRenderFns }