<template>
    <pendo-card
        title="Prompt settings"
        class="prompt-settings-card">
        <template #body>
            <pendo-form
                ref="PromptSettingsForm"
                :model="model"
                @hook:mounted="validateForm">
                <pendo-form-item
                    prop="appName"
                    label="App"
                    :rules="{
                        required: true,
                        message: 'App is required',
                        trigger: 'change'
                    }">
                    <pendo-multiselect
                        full-width
                        max-trigger-width="100"
                        :options="apps"
                        :allow-empty="false"
                        label-key="displayName"
                        :value="selectedApp"
                        @select="onAppSelect">
                        <template #selectedLabel="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>
                        <template #option="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>
                    </pendo-multiselect>
                </pendo-form-item>
                <pendo-form-item
                    class="alias-form-item"
                    prop="appAlias">
                    <template #label>
                        <bracket />
                        <span>
                            App alias
                            <pendo-icon
                                v-pendo-tooltip="appAliasTooltipConfig"
                                class="alias-info"
                                type="info"
                                size="14" />
                        </span>
                    </template>
                    <pendo-input
                        data-cy="guide-create--alias-input"
                        :value="model.appAlias"
                        @change="onAppAliasUpdate" />
                </pendo-form-item>
                <pendo-form-item
                    prop="url"
                    label="Guide start URL"
                    :rules="urlValidationRule">
                    <div class="create-guide-app-url-field">
                        <pendo-input
                            :value="url"
                            data-cy="guide-create--url-input"
                            class="create-guide-form-input"
                            placeholder="https://yourapp.com"
                            @change="onUrlUpdate">
                            <template #prefix>
                                <pendo-icon
                                    :class="{ 'create-guide-app-url-icon--empty': !url }"
                                    type="link"
                                    size="16" />
                            </template>
                        </pendo-input>
                    </div>
                </pendo-form-item>
                <div
                    v-if="isPendoUser"
                    class="pendo-form-item">
                    <div>
                        <pendo-button
                            icon="git-merge"
                            type="tertiary"
                            class="toggle-lookaside-button"
                            @click="isLookasideInputVisible = !isLookasideInputVisible">
                            (Super only) provide lookaside host
                        </pendo-button>
                        <pendo-icon
                            v-pendo-tooltip="lookasideTooltipConfig"
                            display="inline"
                            class="lookaside-host-icon"
                            type="info"
                            size="14" />
                    </div>
                    <pendo-form-item
                        v-if="isLookasideInputVisible"
                        :rules="lookasideHostValidationRules"
                        prop="lookasideHost">
                        <pendo-input
                            :value="model.lookasideHost"
                            data-cy="guide-create--lookaside-input"
                            autofocus
                            placeholder="[name].lookaside.pendo-[env].pendo-dev.com (no trailing slash)"
                            @change="onLookasideHostUpdate">
                            <template #prepend>
                                https://
                            </template>
                        </pendo-input>
                    </pendo-form-item>
                </div>
                <div
                    v-if="hasKbBetaFlag"
                    class="kb-content">
                    <pendo-divider />
                    <div class="kb-label">
                        <p class="pendo-form-item__label">
                            Knowledge Base
                        </p>
                        <pendo-tag
                            label="BETA"
                            color="#8029E9"
                            size="mini"
                            round
                            subtle />
                    </div>
                    <pendo-toggle
                        v-if="appKnowledgeBase"
                        class="kb-toggle"
                        label="Use my Knowledge Base"
                        :value="useKb"
                        @change="onKbToggleUpdate" />
                    <p class="kb-helper-text">
                        {{ kbHelperText }}
                    </p>
                    <p
                        v-if="showAdminLink"
                        class="kb-helper-text">
                        {{ noKbHelperText }} visit
                        <a
                            rel="noopener"
                            :href="appSettingsUrl"
                            target="_blank">
                            Tagging & Guide Settings.
                        </a>
                    </p>
                    <p
                        v-if="showNonAdminLink"
                        class="kb-helper-text">
                        {{ noKbHelperText }} share this link with a subscription admin.
                        <pendo-input
                            :disabled="true"
                            :value="appSettingsUrl"
                            class="kb-settings-url">
                            <template #append>
                                <pendo-button
                                    v-pendo-tooltip="{ content: 'Copy link', arrow: true }"
                                    v-pendo-clipboard="appSettingsUrl"
                                    icon="link" />
                            </template>
                        </pendo-input>
                    </p>
                </div>
            </pendo-form>
        </template>
    </pendo-card>
</template>

<script>
import {
    PendoButton,
    PendoCard,
    PendoClipboard,
    PendoDivider,
    PendoForm,
    PendoFormItem,
    PendoIcon,
    PendoIconOption,
    PendoInput,
    PendoMultiselect,
    PendoTag,
    PendoToggle,
    PendoTooltip
} from '@pendo/components';
import { urlValidationRule, lookasideHostValidationRules } from '@pendo/services/Formatters';
import Bracket from '../../icons/Bracket.vue';
import capitalize from 'lodash/capitalize';

export default {
    name: 'PromptSettingsWithAI',
    components: {
        Bracket,
        PendoButton,
        PendoCard,
        PendoDivider,
        PendoForm,
        PendoFormItem,
        PendoIcon,
        PendoInput,
        PendoIconOption,
        PendoMultiselect,
        PendoTag,
        PendoToggle
    },
    directives: {
        PendoTooltip,
        PendoClipboard
    },
    props: {
        apps: {
            type: Array,
            default: () => []
        },
        appAlias: {
            type: String,
            default: ''
        },
        selectedApp: {
            type: Object,
            required: true
        },
        url: {
            type: String,
            default: ''
        },
        isPendoUser: {
            type: Boolean,
            default: false
        },
        lookasideHost: {
            type: String,
            default: ''
        },
        hasKbBetaFlag: {
            type: Boolean,
            defualt: false
        },
        kbAppMap: {
            type: Object,
            default: () => ({})
        },
        appSettingsUrl: {
            type: String,
            default: ''
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        useKb: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            urlValidationRule,
            isValidUrl: true,
            isLookasideInputVisible: false,
            lookasideHostValidationRules,
            looksasidePlaceholder:
                'adopt.pendo-[env].pendo-dev.com/in-app-designer/[name]/designer.html (no trailing slash)',
            lookasideTooltipConfig: {
                content: 'Lookaside host option is only visible to users with @pendo.io in their emails',
                arrow: true,
                classes: 'is-multi-line'
            },
            appAliasTooltipConfig: {
                trigger: 'hover',
                content: "App alias will be used in place of the app's name in your guide"
            },
            model: {
                url: '',
                appName: '',
                appAlias: '',
                lookasideHost: ''
            },
            noKbHelperText:
                'Knowledge Bases can inform Pendo AI about your application. To configure a Knowledge Base for the selected app, '
        };
    },
    computed: {
        kbHelperText () {
            if (!this.appKnowledgeBase) return '';

            return `When this option is enabled, guides are generated using content from your ${capitalize(
                this.appKnowledgeBase.provider
            )} Knowledge Base (https://${this.appKnowledgeBase.url}).`;
        },
        appKnowledgeBase () {
            return this.kbAppMap[this.selectedApp.id];
        },
        showAdminLink () {
            return !this.appKnowledgeBase && this.isAdmin;
        },
        showNonAdminLink () {
            return !this.appKnowledgeBase && !this.isAdmin;
        }
    },
    watch: {
        selectedApp (newValue) {
            this.model.appName = newValue.displayName;
            this.validateForm();
        },
        url (newValue) {
            this.model.url = newValue;
            this.validateForm();
        },
        appAlias (newValue) {
            this.model.appAlias = newValue;
            this.validateForm();
        },
        lookasideHost (newValue) {
            this.model.lookasideHost = newValue;
            this.validateForm();
        }
    },
    async beforeMount () {
        this.model.appName = this.selectedApp.displayName;
        this.model.appAlias = this.appAlias;
        this.model.url = this.url;
        this.model.lookasideHost = this.lookasideHost;
    },
    methods: {
        async onUrlUpdate (url) {
            this.$emit('urlUpdated', url);
            await this.$nextTick();
            this.validateForm();
        },
        async onAppSelect (app) {
            this.$emit('appSelected', app);
            await this.$nextTick();
            this.validateForm();
        },
        async onAppAliasUpdate (alias) {
            this.$emit('appAliasUpdated', alias);
            await this.$nextTick();
            this.validateForm();
        },
        async onLookasideHostUpdate (lookasideHost) {
            this.$emit('lookasideHostUpdated', lookasideHost);
            await this.$nextTick();
            this.validateForm();
        },
        async validateForm () {
            if (!this.$refs.PromptSettingsForm || !this.$refs.PromptSettingsForm.validate) return;

            const isValid = await this.$refs.PromptSettingsForm.validate(null, true).catch(() => false);
            this.$emit('promptSettingsValidated', isValid);
        },
        async onKbToggleUpdate (value) {
            this.$emit('useKbUpdated', value);
        }
    }
};
</script>

<style scoped lang="scss">
.alias-form-item {
    position: relative;
    top: -10px;
    right: -10px;
    width: calc(100% - 10px);
}

.alias-info {
    display: inline-block;
    width: fit-content;
    height: fit-content;
}

.prompt-input {
    padding-top: 10px;
    flex-flow: row nowrap;
    display: flex;
    gap: 3px;
}

.prompt-tooltip {
    display: flex;
    gap: 3px;
}

.lookaside-host-icon {
    margin-left: 5px;
}

.kb-toggle {
    margin-top: 4px;
    margin-bottom: 10px;
}

.kb-helper-text {
    color: $gray-lighter-2;
}

.kb-label {
    display: flex;
    align-items: center;

    ::v-deep .pendo-tag {
        margin-left: 5px;
    }
}

.kb-settings-url {
    margin-top: 10px;

    ::v-deep {
        .pendo-button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .pendo-input__append {
            padding: 0;
        }
    }
}
</style>
