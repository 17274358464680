import { createLayoutBlockWithText } from '@pendo/services/Adopt';
import { BuildingBlock } from '@pendo/services/BuildingBlocks';
import { BUILDING_BLOCK_LAYOUT_TYPES } from '@pendo/services/Constants';
import { v4 as uuid } from 'uuid';

export async function generateSteps ({ http, appName, maxNumberOfSteps, description, assistantType, appId }) {
    const { data: arrayOfSteps } = await http.post(`/api/s/_SID_/ai/application/${appId}/guide/generateguidecontent`, {
        appName,
        maxNumberOfSteps,
        description,
        assistantType
    });

    return arrayOfSteps;
}

export async function generateStepsFromKnowledgeBase ({
    http,
    appName,
    maxNumberOfSteps,
    description,
    assistantType,
    orgId,
    subId
}) {
    const { data: arrayOfSteps } = await http.post(
        `/api/organization/${orgId}/knowledgeStore/generation/guideContent`,
        {
            subId,
            appName,
            maxNumberOfSteps,
            description,
            assistantType
        }
    );

    return arrayOfSteps;
}

export async function regenerateStepTextContent ({ http, stepTextContent, assistantType, appId }) {
    const { data } = await http.post(`/api/s/_SID_/ai/application/${appId}/guide/writingassistant`, {
        content: stepTextContent,
        assistantType
    });

    return data.editedContent;
}

export function isGeneratedStepsValid (stepsArray) {
    const { TOOLTIP, LIGHTBOX } = BUILDING_BLOCK_LAYOUT_TYPES;

    const validSteps = stepsArray.filter((step) => {
        const hasValidStepType = step.stepType && [TOOLTIP, LIGHTBOX].includes(step.stepType);
        const hasValidTextContent = step.stepTextContent && typeof step.stepTextContent === 'string';

        return hasValidStepType && hasValidTextContent;
    });

    return validSteps.length === stepsArray.length;
}

export function prepStepsForPreview (steps, theme) {
    return steps.map((step, stepIndex) => {
        const buttonText = stepIndex === steps.length - 1 ? 'Done' : 'Next';
        const buildingBlocks = createLayoutBlockWithText({
            buttonText,
            theme,
            text: step.stepTextContent,
            stepIndex,
            layoutType: step.stepType
        });

        const id = step.id || uuid();

        return {
            ...step,
            id,
            // key is used for step preview reactivity
            key: `Step${stepIndex + 1}-${step.stepTextContent}-${step.stepType}-${buttonText}`,
            name: `Step ${stepIndex + 1}`,
            dom: JSON.stringify(BuildingBlock.buildingBlocksToDom(buildingBlocks)),
            buildingBlocks
        };
    });
}

export default {
    generateSteps,
    isGeneratedStepsValid
};
