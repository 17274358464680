<template>
    <pendo-card
        :class="cardClassList"
        :title="cardTitle"
        :body-min-height="cardBodyMinHeight">
        <pendo-loading-indicator
            v-if="loading"
            size="large" />
        <pendo-empty-state
            v-if="!loading && !pollCount"
            :icon="emptyStateIconConfig"
            title="No Polls Found"
            description="This guide has no polls" />
        <pendo-collapse
            v-if="!loading && pollCount"
            :force-always-open="polls.length === 1 ? ['poll-question-0'] : []">
            <pendo-collapse-item
                v-for="(poll, index) in polls"
                :key="poll.id"
                :name="`poll-question-${index}`"
                :expanded="poll.id === focusedPollId"
                :title="poll.question"
                type="card">
                <template #title>
                    <div>
                        {{ poll.question }}
                        <pendo-tag
                            v-if="!pollStepsMap[poll.id]"
                            v-pendo-tooltip="'This poll has been removed from the guide'"
                            class="poll-question--tag"
                            type="warning">
                            Inactive
                        </pendo-tag>
                    </div>
                    <slot
                        name="actions"
                        :poll="poll" />
                </template>
                <div
                    :ref="`poll-${poll.id}-anchor`"
                    class="poll-anchor" />
                <pendo-collapse-item
                    :name="`poll-question-${index}-details`"
                    expanded
                    title="Poll Details"
                    type="details">
                    <poll-details
                        :poll="poll"
                        :guide="guide"
                        :step="pollStepsMap[poll.id]"
                        :apps="apps"
                        :loading-visitor-count="visitorCounts[poll.id].loading"
                        :visitor-count="visitorCounts[poll.id].value"
                        :show-response-count="showDetailsResponseCount"
                        :loading-response-count="totalResponseCounts[poll.id].loading"
                        :response-count="totalResponseCounts[poll.id].value"
                        :loading-unique-response-count="totalResponseCounts[poll.id].loading"
                        :unique-response-count="uniqueResponseCounts[poll.id].value"
                        :invalid-response-count="invalidResponseCounts[poll.id].value" />
                </pendo-collapse-item>
                <pendo-collapse-item
                    v-if="shouldShowPollActivity(poll)"
                    :name="`poll-question-${index}-activity`"
                    expanded
                    title="Poll Activity"
                    type="details">
                    <poll-activity-chart
                        :poll="poll"
                        :agg-state="responseCounts[poll.id].state"
                        :height="responseCounts[poll.id].height"
                        :response-counts="responseCounts[poll.id].value" />
                </pendo-collapse-item>
                <pendo-collapse-item
                    expanded
                    type="details"
                    :name="`poll-question-${index}-responses`"
                    :title="
                        `Poll Responses
                            ${responseLists[poll.id].loading ? '' : ` (${responseLists[poll.id].value.length})`}`
                    ">
                    <poll-response-list
                        :agg-state="responseLists[poll.id].state"
                        :height="responseLists[poll.id].height"
                        :response-list="responseLists[poll.id].value"
                        :metadata="metadata"
                        :timezone="timezone"
                        :use-resource-column-manager="useResourceColumnManager"
                        :features-list="featuresList"
                        :pages-list="pagesList"
                        :guides-list="guidesList"
                        :is-loading-resources="isLoadingResources"
                        :is-loading-metadata="isLoadingMetadata"
                        :table-user-settings="tableUserSettings"
                        :already-feedback-linked-responses="alreadyFeedbackLinkedResponses"
                        :get-init-feedback-in-progress="getInitFeedbackInProgress"
                        :creating-feedback-in-progress="creatingFeedbackInProgress"
                        :poll="poll"
                        :guide="guide"
                        :show-feeback-column="showFeebackColumn(poll)"
                        @columnsUpdate="$emit('columnsUpdate', $event)"
                        @fetchResources="$emit('fetchResources', $event)"
                        @onConfirmColumnsChange="$emit('confirmColumnsChange', $event)"
                        @tableSettingsUpdate="$emit('tableSettingsUpdate', $event)"
                        @createFeedbackItem="createFeedbackItem"
                        @openFeedbackItem="openFeedbackItem" />
                </pendo-collapse-item>
            </pendo-collapse-item>
        </pendo-collapse>
    </pendo-card>
</template>

<script>
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import {
    PendoCard,
    PendoCollapse,
    PendoCollapseItem,
    PendoEmptyState,
    PendoLoadingIndicator,
    PendoNotification,
    PendoTag,
    PendoTooltip
} from '@pendo/components';
import { LOADING_STATES } from '@pendo/services/Constants';
import PollDetails from '@/stateless-components/guides/polls/PollDetails';
import PollActivityChart from '@/stateless-components/guides/polls/PollActivityChart';
import PollResponseList from '@/stateless-components/guides/polls/PollResponseList';

const getAggState = (status, value) => {
    if (status === LOADING_STATES.RESOLVED && value.length === 0) return LOADING_STATES.EMPTY;

    return status;
};

const getWidgetHeight = (state) => {
    if ([LOADING_STATES.LOADING, LOADING_STATES.EMPTY].includes(state)) return '230px';

    return 'auto';
};

export default {
    name: 'PollResponsesCard',
    components: {
        PendoCard,
        PendoCollapse,
        PendoCollapseItem,
        PendoEmptyState,
        PendoLoadingIndicator,
        PendoTag,
        PollActivityChart,
        PollResponseList,
        PollDetails
    },
    directives: {
        PendoTooltip
    },
    props: {
        polls: {
            type: Array,
            required: true
        },
        focusedPollId: {
            type: String,
            default: null
        },
        apps: {
            type: Array,
            required: true
        },
        guide: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false
        },
        showDetailsResponseCount: {
            type: Boolean,
            default: false
        },
        /* object of agg connect stream instances keyed by pollId */
        totalResponseCountsAggs: {
            type: Object,
            default: null
        },
        /* object of agg connect stream instances keyed by pollId */
        uniqueResponseCountsAggs: {
            type: Object,
            default: null
        },
        /* object of agg connect stream instances keyed by pollId */
        invalidResponseCountsAggs: {
            type: Object,
            default: null
        },
        /* object of agg connect stream instances keyed by pollId */
        visitorCountsAggs: {
            type: Object,
            default: null
        },
        /* object of agg connect stream instances keyed by pollId */
        responseCountsAggs: {
            type: Object,
            default: null
        },
        /* object of agg connect stream instances keyed by pollId */
        responseListsAggs: {
            type: Object,
            default: null
        },
        metadata: {
            type: Array,
            default: () => []
        },
        timezone: {
            type: String,
            default: ''
        },
        tableUserSettings: {
            type: Object,
            default: () => ({})
        },
        useResourceColumnManager: {
            type: Boolean,
            default: false
        },
        featuresList: {
            type: Array,
            default: () => []
        },
        pagesList: {
            type: Array,
            default: () => []
        },
        guidesList: {
            type: Array,
            default: () => []
        },
        isLoadingResources: {
            type: Boolean,
            default: false
        },
        isLoadingMetadata: {
            type: Boolean,
            default: false
        },
        alreadyFeedbackLinkedResponses: {
            type: Map,
            default: () => {}
        },
        getInitFeedbackInProgress: {
            type: Boolean,
            default: false
        },
        creatingFeedbackInProgress: {
            type: Array,
            default: () => []
        },
        canShowFeebackColumn: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            emptyStateIconConfig: {
                'type': 'alert-circle',
                'size': 36,
                'stroke-width': 1.5
            },
            visitorCounts: {},
            totalResponseCounts: {},
            uniqueResponseCounts: {},
            invalidResponseCounts: {},
            responseCounts: {},
            responseLists: {}
        };
    },
    computed: {
        pollCount () {
            return get(this.polls, 'length', 0);
        },
        pollStepsMap () {
            if (!this.guide?.steps) return {};

            return this.polls.reduce((map, poll) => {
                map[poll.id] = this.guide.steps.find(({ pollIds }) => pollIds?.includes(poll.id)) || null;

                return map;
            }, {});
        },
        cardClassList () {
            const classList = ['poll-responses-card'];
            if (this.loading || !this.pollCount) classList.push('no-polls');

            return classList;
        },
        cardTitle () {
            const title = 'Questions';
            if (this.loading) return title;

            return `${title} (${this.pollCount})`;
        },
        cardBodyMinHeight () {
            if (!this.loading && this.pollCount) return '0';

            return '300';
        }
    },
    watch: {
        totalResponseCountsAggs: {
            immediate: true,
            handler (map) {
                if (map) this.connectCountAggs(map, 'totalResponseCounts');
            }
        },
        uniqueResponseCountsAggs: {
            immediate: true,
            handler (map) {
                if (map) this.connectCountAggs(map, 'uniqueResponseCounts');
            }
        },
        invalidResponseCountsAggs: {
            immediate: true,
            handler (map) {
                if (map) this.connectCountAggs(map, 'invalidResponseCounts');
            }
        },
        visitorCountsAggs: {
            immediate: true,
            handler (map) {
                if (map) this.connectVisitorCountsAggs(map);
            }
        },
        responseCountsAggs: {
            immediate: true,
            handler (map) {
                if (map) this.connectResponseCountsAggs(map);
            }
        },
        responseListsAggs: {
            handler (map) {
                if (map) this.connectResponseListsAggs(map);
            }
        }
    },
    created () {
        this.initPollData();
    },
    mounted () {
        this.checkFocusedPollId();
    },
    methods: {
        checkFocusedPollId () {
            const { focusedPollId } = this;
            if (!focusedPollId) return;
            if (!this.polls.find(({ id }) => id === focusedPollId)) {
                this.$emit('focusedPollIdNotFound');
                PendoNotification({
                    type: 'error',
                    title: 'Poll Not Found',
                    message: `Poll with id ${focusedPollId} was not found on this guide`
                });

                return;
            }
            this.$refs[`poll-${this.focusedPollId}-anchor`][0]?.scrollIntoView();
        },
        initPollData () {
            const pollResponseMap = this.polls.reduce((map, poll) => {
                const { id: pollId, idResponses } = poll;
                const defaultData = { value: null, loading: true };
                const state = LOADING_STATES.LOADING;
                const height = getWidgetHeight(LOADING_STATES.LOADING);
                const defaultWidgetData = { ...defaultData, state, height };
                this.visitorCounts[pollId] = this.pollStepsMap[pollId] ? defaultData : { loading: false }; // if poll not attached to step, there's no visitor count to get
                this.totalResponseCounts[pollId] = defaultData;
                this.uniqueResponseCounts[pollId] = defaultData;
                this.invalidResponseCounts[pollId] = defaultData;
                this.responseCounts[pollId] = defaultWidgetData;
                this.responseLists[pollId] = defaultWidgetData;
                const cases = [];

                if (idResponses) {
                    Object.keys(idResponses).forEach((key) => {
                        const value = idResponses[key];
                        if (!isNaN(key)) key = toNumber(key);
                        cases.push({ value, '==': key });
                    });

                    map[poll.id] = cases;
                }

                return map;
            }, {});
            this.$emit('pollResponseMapSet', pollResponseMap);
        },
        connectCountAggs (aggsMap, countsMapName) {
            Object.entries(aggsMap).forEach(([pollId, agg]) => {
                agg.subscribe(({ status, value }) => {
                    const newValues = {
                        [pollId]: {
                            loading: status === LOADING_STATES.LOADING,
                            value: status === LOADING_STATES.RESOLVED ? value[0].count : null
                        }
                    };

                    this[countsMapName] = {
                        ...this[countsMapName],
                        ...newValues
                    };
                });
            });
        },
        connectVisitorCountsAggs (map) {
            Object.entries(map).forEach(([stepId, agg]) => {
                const { pollIds } = this.guide.steps.find(({ id }) => id === stepId);
                agg.subscribe(({ status, value }) => {
                    const newStepVisitorCounts = pollIds.reduce((map, pollId) => {
                        map[pollId] = {
                            loading: status === LOADING_STATES.LOADING,
                            value: status === LOADING_STATES.RESOLVED ? value[0].count : null
                        };

                        return map;
                    }, {});
                    this.visitorCounts = {
                        ...this.visitorCounts,
                        ...newStepVisitorCounts
                    };
                });
            });
        },
        connectResponseCountsAggs (map) {
            Object.entries(map).forEach(([pollId, agg]) => {
                agg.subscribe(({ status, value }) => {
                    const state = getAggState(status, value);
                    const height = getWidgetHeight(state);
                    const newPollResponseCounts = {
                        [pollId]: {
                            state,
                            height,
                            value: status === LOADING_STATES.RESOLVED ? value : null
                        }
                    };

                    this.responseCounts = {
                        ...this.responseCounts,
                        ...newPollResponseCounts
                    };
                });
            });
        },
        connectResponseListsAggs (map) {
            Object.entries(map).forEach(([pollId, agg]) => {
                agg.subscribe(({ status, value }) => {
                    const state = getAggState(status, value);
                    const height = getWidgetHeight(state);
                    const newPollResponseList = {
                        [pollId]: {
                            state,
                            height,
                            loading: status === LOADING_STATES.LOADING,
                            value:
                                status === LOADING_STATES.RESOLVED
                                    ? value.map((res) => {
                                        const { visitor: metadata } = res;

                                        return {
                                              ...res,
                                              metadata,
                                              uniqueKey: `${res.browserTime}-${res.visitorId}`
                                        };
                                    })
                                    : null
                        }
                    };

                    this.responseLists = {
                        ...this.responseLists,
                        ...newPollResponseList
                    };
                });
            });
        },
        shouldShowPollActivity (poll) {
            const pollType = get(poll, 'attributes.type');

            return !['FreeForm', 'NPSReason'].includes(pollType);
        },
        createFeedbackItem ({ row, pollResponseId }) {
            this.$emit('createFeedbackItem', { row, pollResponseId });
        },
        openFeedbackItem (pollResponseId) {
            this.$emit('openFeedbackItem', pollResponseId);
        },
        showFeebackColumn (poll) {
            return this.canShowFeebackColumn && poll.attributes?.type === 'FreeForm';
        }
    }
};
</script>

<style lang="scss" scoped>
.poll-responses-card {
    &.no-polls {
        ::v-deep .pendo-card__body {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .pendo-card__filters {
        border-bottom: 1px solid $gray-lighter-5;
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        .pendo-tag + .pendo-tag {
            margin-left: 4px;
        }

        > * {
            margin: 4px;
        }
    }
}

.poll-question--tag {
    margin-left: 16px;
}

.poll-anchor {
    position: relative;
    top: -144px;
    left: 0;
}
</style>
