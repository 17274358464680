<template>
    <pendo-card
        v-pendo-loading:feather="isFetching"
        :title="chartTitle"
        class="visitors-over-time">
        <template #filters>
            <active-segment-tag :active-segment="activeSegment" />
            <active-date-range-tag :active-date-range="activeDateRange" />
            <pendo-tag
                :prefix-icon="{
                    type: 'circle',
                    stroke: filterLegendColorMap('visitors'),
                    fill: filterLegendColorMap('visitors'),
                    size: 12
                }"
                type="filter"
                label="Visitors" />
        </template>
        <div
            ref="visitors-over-time"
            class="pendo-highcharts-container visitors-over-time--chart" />
    </pendo-card>
</template>

<script>
import { PendoCard, PendoLoading, PendoTag } from '@pendo/components';
import { CHART_COLORS } from '@/utils/highcharts';
import ActiveSegmentTag from '@/components/filters/ActiveSegmentTag';
import ActiveDateRangeTag from '@/components/filters/ActiveDateRangeTag';

export default {
    name: 'VisitorsOverTime',
    components: {
        ActiveSegmentTag,
        ActiveDateRangeTag,
        PendoCard,
        PendoTag
    },
    directives: {
        PendoLoading
    },
    props: {
        visitorsOverTime: {
            type: Array,
            default: () => []
        },
        activeDateRange: {
            type: Object,
            default: () => ({})
        },
        isFetching: {
            type: Boolean,
            default: false
        },
        activeSegment: {
            type: Object,
            default: () => ({})
        },
        activeSubscriptionUtcOffset: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            chart: null
        };
    },
    computed: {
        seriesData () {
            return this.visitorsOverTime.map(({ visitorCount, timestamp }) => ({ x: timestamp, y: visitorCount }));
        },
        xDateFormat () {
            if (this.activeDateRange.count > 30) {
                return 'Week of %b %d';
            }

            return '%b %d';
        },
        chartTitle () {
            return 'Visitors Over Time Over All Apps';
        },
        chartConfig () {
            const seriesConfig = {
                name: 'Visitors',
                type: 'area',
                data: this.seriesData,
                showInLegend: false
            };

            return {
                series: [seriesConfig],
                chart: {
                    type: 'area',
                    height: 316
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                time: {
                    timezoneOffset: this.activeSubscriptionUtcOffset
                },
                tooltip: {
                    padding: 16
                },
                plotOptions: {
                    series: {
                        tooltip: { xDateFormat: this.xDateFormat },
                        fillColor: {
                            linearGradient: [0, 0, 0, 300],
                            stops: [
                                [0, CHART_COLORS.green50],
                                [1, CHART_COLORS.green10]
                            ]
                        },
                        lineColor: '#22998F',
                        marker: {
                            fillColor: CHART_COLORS.green10,
                            lineColor: CHART_COLORS.green50,
                            states: {
                                hover: {
                                    fillColor: CHART_COLORS.green100,
                                    lineColor: CHART_COLORS.green10
                                }
                            }
                        }
                    }
                },
                yAxis: {
                    allowDecimals: false,
                    title: {
                        text: null
                    }
                },
                xAxis: {
                    startOfWeek: 0,
                    dateTimeLabelFormats: {
                        day: '%b %d',
                        week: '%b %e',
                        month: '%b %Y'
                    },
                    type: 'datetime',
                    labels: {
                        style: {
                            whiteSpace: 'nowrap',
                            textOverflow: 'none'
                        }
                    }
                }
            };
        }
    },
    watch: {
        visitorsOverTime: 'refreshChart'
    },
    mounted () {
        this.refreshChart();
    },
    methods: {
        filterLegendColorMap (filter) {
            return {
                visitors: CHART_COLORS.green100
            }[filter];
        },
        async refreshChart () {
            if (this.chart) {
                this.chart.destroy();
            }
            this.chart = this.$pendo.highcharts.chart(this.$refs['visitors-over-time'], this.chartConfig);
        }
    }
};
</script>
