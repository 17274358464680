var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"poll-details"},[_c('li',[_c('div',{staticClass:"poll-details__label"},[_vm._v(" App ")]),_c('pendo-app-display',{attrs:{"apps":_vm.stepApp}})],1),_c('li',[_c('div',{staticClass:"poll-details__label"},[_vm._v(" Poll Type ")]),_c('div',[_vm._v(_vm._s(_vm.pollType))])]),_c('li',[_c('div',{staticClass:"poll-details__label"},[_vm._v(" Poll Step ")]),_c('div',[_vm._v(_vm._s(_vm.stepNumber))])]),(_vm.showResponseCount)?_c('li',{attrs:{"data-cy":"poll-response-count"}},[_c('div',{staticClass:"poll-details__label"},[_vm._v(" Responses ")]),(_vm.loadingResponseCount)?_c('div',[_c('pendo-loading-indicator',{attrs:{"type":"skeleton","skeleton-props":{
                    height: 13,
                    width: 50
                }}})],1):_vm._e(),(!_vm.loadingResponseCount)?_c('div',[_vm._v(" "+_vm._s(_vm.responseCount)+" ")]):_vm._e()]):_vm._e(),(_vm.showResponseCount)?_c('li',{attrs:{"data-cy":"poll-unique-response-count"}},[_c('div',{staticClass:"poll-details__label"},[_vm._v(" Unique Responses ")]),(!_vm.uniqueResponsesLabel)?_c('div',[_c('pendo-loading-indicator',{attrs:{"type":"skeleton","skeleton-props":{
                    height: 13,
                    width: 50
                }}})],1):_vm._e(),(_vm.uniqueResponsesLabel)?_c('div',[_vm._v(" "+_vm._s(_vm.uniqueResponsesLabel)+" "),(_vm.invalidResponsesLabel)?_c('span',{staticClass:"invalid-responses-label"},[_c('pendo-icon',{staticClass:"invalid-responses-label--icon",attrs:{"type":"alert-triangle","display":"inline","size":"18"}}),_vm._v(" "+_vm._s(_vm.invalidResponsesLabel)+" "),_c('a',{attrs:{"target":"_blank","rel":"noopener","href":"https://support.pendo.io/hc/en-us/articles/360032573652-Troubleshoot-Poll-Error-Messages-Legacy-"}},[_vm._v(" Details ")])],1):_vm._e()]):_vm._e()]):_vm._e(),_c('li',[_c('div',{staticClass:"poll-details__label"},[_vm._v(" Visitors Seen ")]),(_vm.loadingVisitorsSeen)?_c('div',[_c('pendo-loading-indicator',{attrs:{"type":"skeleton","skeleton-props":{
                    height: 13,
                    width: 50
                }}})],1):_vm._e(),(!_vm.loadingVisitorsSeen)?_c('div',[_vm._v(" "+_vm._s(_vm.visitorCount)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }